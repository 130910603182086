import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { ErrorPage, ExtendedFAB, LoadingPage } from '@chocolate-soup-inc/cs-frontend-components';
import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Board } from '../../../components/board/Board';
import { TColumnProps } from '../../../components/board/Column';
import { TDraggableCardRenderOptions } from '../../../components/board/DraggableCard';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { FloatingActions } from '../../../components/floatingActions/FloatingActions';
import { cache } from '../../../config/apollo/cache';
import {
  filterByGiftType,
  filterByRecipient,
  sortCardsShipmentsByDateDESC,
  sortShipments,
  useShipmentsWithAddressInfo,
} from '../../../entities/shipments/shared';
import { ShipmentFieldsFragmentDoc, TGiftTypes, TShipmentStatuses } from '../../../generated/graphql';
import { PrintLabelModal } from '../PrintLabelModal/PrintLabelModal';
import { SetAsReadyToShipModal } from '../SetAsReadyToShipModal/SetAsReadyToShipModal';
import { ShipmentCard } from '../ShipmentCard/ShipmentCard';
import { PDFDocument } from 'pdf-lib';
import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';

import styles from './Shipments.module.scss';
import { serializeError } from 'serialize-error';
import { toast } from 'react-toastify';
import { TShipmentType, useQueryAllOpenShipments } from '../../../entities/shipments/queries';
import { useFilter } from '../../../contexts/filters';
import { ShipManuallyModal } from '../ShipManuallyModal/ShipManuallyModal';

export const columnsDetails = [
  {
    name: 'Packaging',
    destinationColumns: [TShipmentStatuses.ReadyToShip, TShipmentStatuses.ReadyForPickup],
    nextPhase: TShipmentStatuses.ReadyToShip,
    id: TShipmentStatuses.Packaging,
  },
  {
    name: 'Ready to Ship',
    destinationColumns: [TShipmentStatuses.PrintingLabel, TShipmentStatuses.ReadyForPickup],
    nextPhase: TShipmentStatuses.PrintingLabel,
    id: TShipmentStatuses.ReadyToShip,
  },
  {
    name: 'Printing Label',
    destinationColumns: [],
    nextPhase: undefined,
    id: TShipmentStatuses.PrintingLabel,
  },
  {
    name: 'Printed Label',
    destinationColumns: [],
    nextPhase: undefined,
    id: TShipmentStatuses.LabelPrinted,
  },
  {
    name: 'Shipped',
    destinationColumns: [],
    nextPhase: undefined,
    id: TShipmentStatuses.Shipped,
    includeStatuses: [TShipmentStatuses.Returned, TShipmentStatuses.Delivered, TShipmentStatuses.Attention],
  },
];

export const Shipments = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [companyFilter, setCompanyFilter] = useState<string>();
  const [recipientFilter, setRecipientFilter] = useState<string>();
  const [rackFilter, setRackFilter] = useState<string>();
  const [giftTypeFilter, setGiftTypeFilter] = useState<'subscription' | TGiftTypes.NewHire>();
  const [trackingNumberFilter, setTrackingNumberFilter] = useState<string>();

  const { data: noAddressShipments, error, loading } = useQueryAllOpenShipments();

  const [shipManuallyModal, setShipManuallyModal] = useState<boolean>(false);
  const [shipManuallyShipment, setShipManuallyShipment] = useState<TShipmentType | undefined>(undefined);

  const baseShipments = useShipmentsWithAddressInfo({
    shipments: noAddressShipments,
  });

  const shipments = useMemo(() => {
    return _.compact(baseShipments).filter((s) => {
      if (s._deleted) return false;
      return true;
    });
  }, [baseShipments]);

  // If data changes while the modal is active, keep the state up to date
  useEffect(() => {
    if (!shipManuallyShipment) return;
    const { id: shipmentManuallyId } = shipManuallyShipment;
    const newShipManuallyShipment = shipments.find((s) => {
      return s.id === shipmentManuallyId;
    });
    setShipManuallyShipment(newShipManuallyShipment);
  }, [shipments, shipManuallyShipment]);

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(shipments.map((s) => s.company)), 'id');
  }, [shipments]);

  const racks = useMemo(() => {
    return _.uniq(_.compact(_.compact(shipments || []).map((s) => s.rack)));
  }, [shipments]);

  useEffect(() => {
    if (companies.find((c) => c.id === companyFilter) == null) {
      setCompanyFilter(undefined);
    }
  }, [companyFilter, companies]);

  useEffect(() => {
    if (racks.find((r) => r === rackFilter) == null) {
      setRackFilter(undefined);
    }
  }, [rackFilter, racks]);

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      disabled: companies.length === 0,
      includeEmptyOption: true,
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      type: 'singleSelect',
      value: companyFilter,
    });

    filtersList.push({
      label: 'Recipient',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientFilter(undefined);
        else setRecipientFilter(v);
      },
      type: 'textInput',
      value: recipientFilter,
    });

    filtersList.push({
      disabled: racks == null || racks.length === 0,
      includeEmptyOption: true,
      label: 'Rack',
      name: 'rack',
      onChange: (v) => {
        if (v == null) setRackFilter(undefined);
        else setRackFilter(v as string);
      },
      type: 'singleSelect',
      options: racks.map((r) => ({ label: r, value: r })),
      value: rackFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      label: 'Gift Type',
      name: 'giftType',
      onChange: (v) => {
        if (v == null) setGiftTypeFilter(undefined);
        else setGiftTypeFilter(v as 'subscription' | TGiftTypes.NewHire);
      },
      type: 'singleSelect',
      options: [
        {
          label: 'Subscription',
          value: 'subscription',
        },
        {
          label: 'New Hire',
          value: TGiftTypes.NewHire,
        },
      ],
      value: giftTypeFilter,
    });

    filtersList.push({
      label: 'Tracking Number',
      name: 'trackingNumber',
      onChange: (v) => {
        if (v == null) setTrackingNumberFilter(undefined);
        setTrackingNumberFilter(v as string);
      },
      type: 'textInput',
      value: trackingNumberFilter,
    });

    return filtersList;
  }, [companies, companyFilter, giftTypeFilter, rackFilter, racks, recipientFilter, trackingNumberFilter]);

  const filteredShipments = useMemo(() => {
    return shipments?.filter((s) => {
      if (companyFilter != null && s.companyId !== companyFilter) {
        return false;
      }

      if (rackFilter != null && s.rack !== rackFilter) return false;

      if (
        trackingNumberFilter != null &&
        trackingNumberFilter !== '' &&
        !s.trackingNumber?.toLowerCase().startsWith(trackingNumberFilter.toLowerCase())
      ) {
        return false;
      }

      return (
        filterByRecipient({
          shipment: s,
          filter: recipientFilter,
        }) &&
        filterByGiftType({
          shipment: s,
          filter: giftTypeFilter,
        })
      );
    });
  }, [shipments, companyFilter, rackFilter, trackingNumberFilter, recipientFilter, giftTypeFilter]);

  const groupedByStatusShipments = useMemo(() => {
    return _.groupBy(filteredShipments, (s) => s.status);
  }, [filteredShipments]);

  const sortedGroupedByStatusShipments = useMemo(() => {
    return Object.entries(groupedByStatusShipments).reduce((agg, [key, statusShipments]) => {
      agg[key] = statusShipments.sort(sortShipments);
      return agg;
    }, {} as Record<string, TShipmentType[]>);
  }, [groupedByStatusShipments]);

  const renderShipment = useCallback((shipment: TShipmentType, opts?: TDraggableCardRenderOptions) => {
    return (
      <ShipmentCard
        key={shipment.id}
        shipment={shipment}
        options={opts}
        setShipManuallyModal={setShipManuallyModal}
        setShipManuallyShipment={setShipManuallyShipment}
      />
    );
  }, []);

  const columns: TColumnProps<TShipmentType>[] = useMemo(() => {
    return columnsDetails.map((columnDetails) => {
      const statuses = [columnDetails.id, ...(columnDetails.includeStatuses || [])];
      let cards: TColumnProps<TShipmentType>['cards'] = [];
      for (const status of statuses) {
        cards = cards.concat(
          (sortedGroupedByStatusShipments[status] || []).map((shipment) => {
            return {
              data: shipment,
              disabled: _.isEmpty(columnDetails.destinationColumns),
              id: shipment.id,
              render: renderShipment,
            };
          }),
        );
        if (status === TShipmentStatuses.LabelPrinted) cards.sort(sortCardsShipmentsByDateDESC);
      }

      return {
        cards,
        data: {
          destinationColumns: columnDetails.destinationColumns || [],
        },
        id: columnDetails.id,
        name: columnDetails.name,
      };
    });
  }, [renderShipment, sortedGroupedByStatusShipments]);

  const [shipmentsGoingToReadyToShip, setShipmentsGoingToReadyToShip] = useState<TShipmentType[]>();
  const [shipmentsGoingToLabelPrinted, setShipmentsGoingToLabelPrinted] = useState<TShipmentType[]>();

  const updateShipmentFragment = useCallback((shipment: TShipmentType, status: TShipmentStatuses) => {
    cache.writeFragment({
      data: {
        ...shipment,
        status,
      },
      id: cache.identify(shipment),
      fragment: ShipmentFieldsFragmentDoc,
      fragmentName: getFragmentName(ShipmentFieldsFragmentDoc),
    });
  }, []);

  const onDragEnd = useCallback(
    (event: DragEndEvent, selectedShipments: TShipmentType[]) => {
      const droppedColumnId = event.over?.id as TShipmentStatuses | undefined;
      // const draggedShipment = event.active.data.current as TShipmentType | undefined;

      if (droppedColumnId === TShipmentStatuses.ReadyToShip) {
        for (const shipment of selectedShipments) {
          updateShipmentFragment(shipment, droppedColumnId);
        }

        setShipmentsGoingToReadyToShip(selectedShipments);
      } else if (
        droppedColumnId != null &&
        [TShipmentStatuses.LabelPrinted, TShipmentStatuses.PrintingLabel].includes(droppedColumnId)
      ) {
        for (const shipment of selectedShipments) {
          updateShipmentFragment(shipment, TShipmentStatuses.PrintingLabel);
        }

        setShipmentsGoingToLabelPrinted(selectedShipments);
      }
    },
    [updateShipmentFragment],
  );

  const onMoveToReadyToShipCancel = useCallback(() => {
    if (shipmentsGoingToReadyToShip) {
      for (const shipment of shipmentsGoingToReadyToShip) {
        updateShipmentFragment(shipment, shipment.status);
      }

      setShipmentsGoingToReadyToShip(undefined);
    }
  }, [shipmentsGoingToReadyToShip, updateShipmentFragment]);

  const onMoveToReadyToShipSuccess = useCallback(() => {
    setShipmentsGoingToReadyToShip(undefined);
  }, []);

  const onMoveToLabelPrintedCancel = useCallback(() => {
    if (shipmentsGoingToLabelPrinted) {
      for (const shipment of shipmentsGoingToLabelPrinted) {
        updateShipmentFragment(shipment, shipment.status);
      }

      setShipmentsGoingToLabelPrinted(undefined);
    }
  }, [shipmentsGoingToLabelPrinted, updateShipmentFragment]);

  const onMoveToLabelPrintedSuccess = useCallback(() => {
    setShipmentsGoingToLabelPrinted(undefined);
  }, []);

  const [selectedShipments, setSelectedShipments] = useState<TShipmentType[]>([]);

  const onSelectedCardsChange = useCallback(
    (selectedCardsIds: UniqueIdentifier[]) => {
      setSelectedShipments(shipments.filter((s) => selectedCardsIds.includes(s.id)));
    },
    [shipments],
  );

  const shipmentCanBePrinted = useCallback((shipment: TShipmentType) => {
    return (
      shipment.label?.labelFile?.bucket != null &&
      shipment.label?.labelFile?.key != null &&
      shipment.label?.labelFile?.region != null
    );
  }, []);

  const allSelectedCanBePrinted = useMemo(() => {
    if (selectedShipments.length === 0) return false;

    for (const shipment of selectedShipments) {
      if (!shipmentCanBePrinted(shipment)) return false;
    }
    return true;
  }, [selectedShipments, shipmentCanBePrinted]);

  const [printAllLoading, setPrintAllLoading] = useState<boolean>(false);

  const onPrintAllClick = useCallback(async () => {
    setPrintAllLoading(true);

    try {
      const docs = await Promise.all(
        selectedShipments
          .sort((a, b) => {
            return a.id.localeCompare(b.id);
          })
          .map(async (shipment) => {
            const signedURL = await Storage.get(shipment.label?.labelFile?.key as string, {
              bucket: shipment.label?.labelFile?.bucket,
              region: shipment.label?.labelFile?.region,
            });

            const fetchResponse = await fetch(signedURL);
            const doc = await PDFDocument.load(await fetchResponse.arrayBuffer());
            return doc;
          }),
      );

      const pdfDoc = await PDFDocument.create();

      await Promise.all(
        docs.map(async (doc) => {
          const pages = doc.getPages();
          const copiedPages = await pdfDoc.copyPages(
            doc,
            pages.map((_, index) => index),
          );
          for (const page of copiedPages) {
            pdfDoc.addPage(page);
          }
        }),
      );

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'print-labels.pdf';
      link.click();
      link.remove();
    } catch (error) {
      console.error(serializeError(error));
      toast.error('There was an error trying to print all labels.');
    } finally {
      setPrintAllLoading(false);
    }
  }, [selectedShipments]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <>
      {shipManuallyModal && (
        <ShipManuallyModal
          shipment={shipManuallyShipment}
          onCancel={() => {
            setShipManuallyModal(false);
          }}
          onSuccess={() => {
            setShipManuallyModal(false);
          }}
        />
      )}
      {Array.isArray(shipmentsGoingToReadyToShip) && shipmentsGoingToReadyToShip.length > 0 && (
        <SetAsReadyToShipModal
          shipments={shipmentsGoingToReadyToShip}
          onCancel={onMoveToReadyToShipCancel}
          onSuccess={onMoveToReadyToShipSuccess}
        />
      )}
      {Array.isArray(shipmentsGoingToLabelPrinted) && shipmentsGoingToLabelPrinted.length > 0 && (
        <PrintLabelModal
          shipments={shipmentsGoingToLabelPrinted}
          onCancel={onMoveToLabelPrintedCancel}
          onSuccess={onMoveToLabelPrintedSuccess}
        />
      )}
      <div className={styles.shipmentsPage}>
        <Filters filters={filters} />
        <Board<TShipmentType> columns={columns} onDragEnd={onDragEnd} onSelectedCardsChange={onSelectedCardsChange} />
        {allSelectedCanBePrinted && (
          <FloatingActions>
            <ExtendedFAB
              // disabled={printAllLoading}
              label={`Print ${selectedShipments.length} Labels`}
              leadingIcon='print'
              loading={printAllLoading}
              onClick={onPrintAllClick}
              variant='tertiary'
            />
          </FloatingActions>
        )}
      </div>
    </>
  );
};
