import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useCallback, useMemo } from 'react';

import styles from '../SetAsReadyToShipModal/SetAsReadyToShipModal.module.scss';
import { TShipmentType } from '../../../entities/shipments/queries';
import { TCarriers, useSetShipmentAsShippedMutation, useUpdateShipmentMutation } from '../../../generated/graphql';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import format from 'date-fns/format';

export type TSetAsReadyToShipModalProps = {
  shipment?: TShipmentType;
  onCancel: () => void;
  onSuccess: () => void;
};

export const ShipManuallyModal = (props: TSetAsReadyToShipModalProps) => {
  const { shipment, onCancel, onSuccess } = props;

  const formFields = useMemo(() => {
    return [
      {
        type: 'select',
        label: 'Carrier',
        name: 'carrierShipModal',
        inputOptions: {
          type: 'string',
          variant: 'outlined',
          options: [
            { label: 'Canada Post', value: TCarriers.CanadaPost },
            { label: 'Purolator CA', value: TCarriers.PurolatorCa },
            { label: 'DHL Express CA', value: TCarriers.DhlExpressCanada },
          ],
        },
      },
      {
        type: 'textField',
        label: 'Tracking Number',
        name: 'trackingNumberShipModal',
        id: 'trackingNumberShipModal',
        inputOptions: {
          type: 'string',
        },
      },
      {
        type: 'datePicker',
        label: 'Ship Date',
        name: 'actualShippingDateShipModal',
        inputOptions: {
          type: 'date',
          autoComplete: 'off',
          className: styles.numberInput,
        },
      },
      {
        type: 'textField',
        label: 'Ship Cost',
        name: 'costShipModal',
        inputOptions: {
          type: 'number',
        },
      },
    ];
  }, []);

  const schema = useMemo(() => {
    return Joi.object().unknown(false).keys({
      trackingNumberShipModal: Joi.string().required(),
      actualShippingDateShipModal: Joi.date().required(),
      carrierShipModal: Joi.string().required(),
      costShipModal: Joi.number().required(),
    });
  }, []);

  const [updateShipment] = useUpdateShipmentMutation();
  const [setShipmentAsShipped] = useSetShipmentAsShippedMutation();

  const onSubmit = useCallback<TControlledFormProps<any>['onValidSubmit']>(
    (data) => {
      const updateInput = {
        id: shipment?.id as string,
        companyId: shipment?.companyId as string,
        version: shipment?._version as number,
        input: {
          actualShippingDate: format(data.actualShippingDateShipModal, 'yyyy-MM-dd'),
          trackingNumber: data.trackingNumberShipModal,
          label: {
            carrier: data.carrierShipModal,
            cost: data.costShipModal,
            createdAt: new Date(data.actualShippingDateShipModal).toISOString(),
          },
        },
      };

      return updateShipment({
        variables: updateInput,
      })
        .then(({ data }) => {
          setShipmentAsShipped({
            variables: {
              id: shipment?.id as string,
              companyId: shipment?.companyId as string,
              version: data?.updateShipment?._version as number,
            },
          })
            .then(() => {
              toast.success('Shipment status has been updated.');
            })
            .catch((error) => {
              console.error(serializeError(error));
              toast.error('There was an error updating the shipment status.');
            });
          toast.success('Shipment details have been updated.');
          onSuccess();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error updating the shipment information.');
        });
    },
    [onSuccess, shipment, updateShipment, setShipmentAsShipped],
  );

  return (
    <ModalForm
      closeModal={onCancel}
      confirmLabel='Update'
      contentClassName={styles.setAsReadyToShipModal}
      controlledFormProps={{
        className: styles.form,
        fields: formFields as TControlledFormComponentProps[],
        formProps: {
          resolver: joiResolver(schema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline='Update Shipment Details'
      onCancelClick={onCancel}
      size='small'
      supportingText='Provide shipment details from ShipStation'
    />
  );
};
