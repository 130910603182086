import {
  ErrorPage,
  Loading,
  readableDate,
  LoadingPage,
  TableInner,
  TOnRowClick,
} from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { COMPANIES_PATH, COMPANY_PATH, DETAILS_PATH } from '../../../routes/paths';
import { TablePage } from '../../../components/tablePage/TablePage';
import { Cell } from '@tanstack/react-table';
import { TCompanyType, useQueryAllCompanies } from '../../../entities/companies/queries';
import { TListCompanyFieldsFragment } from '../../../generated/graphql';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { CustomEmployeeCountCell } from '../../../components/customEmployeeCountCell/CustomEmployeeCountCell';
import { useFilter } from '../../../contexts/filters';
import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';
import { useQueryLatestRosterFileByCompanyId } from '../../../entities/rosterFiles/queries';

type TConfiguredCellProps = {
  cell: Cell<TCompanyType<TListCompanyFieldsFragment>, unknown>;
};

enum TCompanyStatuses {
  Active = 'active',
  Inactive = 'inactive',
  Canceled = 'canceled',
}
const LastRosterUpload = ({ companyId }: { companyId: string }) => {
  const { data, loading } = useQueryLatestRosterFileByCompanyId(companyId);
  if (loading) return <Loading className={clsx(tableStyles.tableLoading)} />;
  return <span>{data?.impoortEndedAt ? readableDate(data?.impoortEndedAt) : '-'}</span>;
};

const ConfiguredCell = (props: TConfiguredCellProps) => {
  const { cell } = props;

  const fullyConfigured = useMemo(() => {
    return cell?.row?.original?.fullyConfigured;
  }, [cell?.row?.original?.fullyConfigured]);

  if (fullyConfigured) {
    return (
      <div className={clsx(tableStyles.tableSpaceTertiary)}>
        <span>Configured</span>
      </div>
    );
  } else {
    return <Loading className={clsx(tableStyles.tableLoading)} />;
  }
};

export const List = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const navigate = useNavigate();
  const { data: companies, loading, error } = useQueryAllCompanies();

  const getRowId = useCallback((company: TCompanyType<TListCompanyFieldsFragment>) => {
    return company.id;
  }, []);

  const onRowClick: TOnRowClick<TCompanyType<TListCompanyFieldsFragment>> = useCallback(
    (row) => {
      navigate(
        generatePath(`${COMPANIES_PATH}/${COMPANY_PATH}/${DETAILS_PATH}`, {
          companyId: row.id,
        }),
      );
    },
    [navigate],
  );

  const [statusFilter, setStatusFilter] = useState<string>();

  useEffect(() => {
    setStatusFilter(TCompanyStatuses.Active);
  }, [setStatusFilter]);

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push({
      type: 'singleSelect',
      label: 'Status',
      name: 'status',
      onChange: (v) => {
        setStatusFilter((v as TCompanyStatuses) ?? undefined);
      },
      options: [
        {
          label: 'Active',
          value: TCompanyStatuses.Active,
        },
        {
          label: 'Inactive',
          value: TCompanyStatuses.Inactive,
        },
        {
          label: 'Cancelled',
          value: TCompanyStatuses.Canceled,
        },
      ],
      value: statusFilter,
    });

    return filtersList;
  }, [statusFilter]);

  const filteredCompanies = useMemo(() => {
    const sortedCompanies = companies.sort((a, b) => a.name.localeCompare(b.name));
    return sortedCompanies.filter((s) => {
      const hasReachedEndDate = s.endDate && new Date(s.endDate as string).getTime() < new Date().getTime();
      if (statusFilter !== null && statusFilter !== undefined) {
        if (statusFilter === TCompanyStatuses.Active) return s.active && s.isLive && !hasReachedEndDate;
        if (statusFilter === TCompanyStatuses.Inactive) return s.active && !s.isLive;
        if (statusFilter === TCompanyStatuses.Canceled) return hasReachedEndDate || (!s.active && !s.isLive);
      }
      return true;
    });
  }, [companies, statusFilter]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <TablePage dataLoading={loading} error={error} title='Companies'>
      <Filters filters={filters} />
      <TableInner<TCompanyType<TListCompanyFieldsFragment>>
        columns={[
          {
            header: 'Employee Count',
            accessorKey: 'employeeCount',
            cell: CustomEmployeeCountCell,
          },
          {
            header: 'Name',
            cell: ({ cell }) => {
              const { name } = cell.row.original;
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>{name}</span>
                </div>
              );
            },
          },
          {
            header: 'Delivery Method',
            cell: ({ cell }) => {
              const { deliveryMethod } = cell.row.original;
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>{deliveryMethod}</span>
                </div>
              );
            },
          },
          {
            header: 'Configuration Done',
            cell: ConfiguredCell,
          },
          {
            header: 'Last Roster Upload',
            cell: ({ cell }) => <LastRosterUpload companyId={cell?.row?.original?.id} />,
          },
        ]}
        data={filteredCompanies}
        emptyText='No companies match this criteria.'
        hoverableRows={true}
        onRowClick={onRowClick}
        getRowId={getRowId}
        virtual={true}
      />
    </TablePage>
  );
};
